import { mapCmsSeoDataToSeoMeta } from '@/lib/cms-mappers/common.mappers';

const accountSettingsSettingsPopulateFields = ['seo.image'];

/**
 * Returns "account settings" settings data fetched from CMS (Strapi)
 */
export async function useCmsAccountSettingsSettings(): UseCmsResponse<
  null,
  'seoData'
> {
  const { data, error, pending, refresh } = await useStrapi(
    'account-settings',
    {
      offlineSSRFallback: true,
      watchLocale: true,
      queryParams: {
        populate: accountSettingsSettingsPopulateFields.join(','),
      },
    }
  );

  const seoData = computed(() =>
    mapCmsSeoDataToSeoMeta(data.value?.data.attributes?.seo)
  );

  return {
    data: computed(() => null),
    seoData,
    error,
    pending,
    refresh,
  };
}
